.dash-icon {
    font-size: 24px;
    width: 40px;
    height: 40px;
    background-color: hsl(236deg 84% 93%);
    color: hsl(235deg 87% 70%);
}

.card-info-title {
    font-size: 1rem;
    color: rgb(177, 177, 177);
}

.card-info-value {
    font-size: 2rem;
    color: rgb(58, 58, 58);
    text-align: left;
}

.dash-footer {
    display: flex;
    justify-content: space-between;
}

.dash-percent {}

.dash-dateinfo {
    font-size: .9rem;
    color: rgb(177, 177, 177);
}

.pt-10 {
    padding-top: 10px;
}

.fs20 {
    font-size: 20px;
}

.mt0 {
    margin-top: 0px !important;
}