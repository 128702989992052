    // -------------------------
    // --- You can add your custom CSS here and it will overwrite template styles.
    // -------------------------
    @media (min-width: 1200px) {
        .container {
            max-width: 50%;
        }
    }
    
    @media (min-width: 992px) {
        .container {
            max-width: 95%;
        }
    }
    
    @media (min-width: 768px) {
        .container {
            max-width: 92%;
        }
    }
    
    @media (min-width: 576px) {
        .container {
            max-width: 90%;
        }
    }
    
    @media (min-width: 576px) {
        .small {
            max-width: 40%;
        }
    }
    
    @media (min-width: 576px) {
        .medium {
            max-width: 65%;
        }
    }
    
    .links {
        color: #1976D2;
        padding: 0;
        height: 15px;
        border: none;
        position: relative;
        top: -5px;
        background-color: transparent;
    }
    
    .links:hover {
        background-color: transparent;
    }
    
    .links>.dx-button-content {
        padding: 0;
    }
    
    .cellDetail {
        font-size: 12px !important;
    }
    
    .dx-toolbar-label .dx-toolbar-item-content>div {
        overflow: visible;
    }
    
    .dx-toolbar-label>div {
        overflow: visible;
    }
    
    .dx-datagrid-rowsview .dx-row.dx-row-lines:first-child>tr {
        font-weight: bold;
    }
    
    .dx-datagrid-rowsview .dx-row:first-child>td.libro {
        font-weight: bold;
    }
    
    .asiento-anulado {
        background-color: #ffaeaea1;
    }
    
    .asiento-cuadra {
        background-color: #aeffb2a1;
    }
    
    .asiento-nocuadra {
        background-color: #ffaeaea1;
    }
    
    .compra-anulado {
        background-color: #ffaeaea1;
    }
    
    .compra-recibida {
        background-color: #aeffb2a1;
    }
    
    .estado-anulado {
        background-color: #ffaeae47;
    }
    
    .cell-editable {
        background-color: rgb(144, 210, 219);
    }
    
    .cell-price {
        background-color: #ffaeaea1;
    }
    
    .cell-bold {
        font-weight: bold;
    }
    
    .resumen {
        float: right;
        width: 185px;
    }
    
    .resumen>div>p,
    .resumen>div>div {
        font-size: 13px;
        font-weight: 500;
    }
    
    .resumen>div:last-child>p {
        font-weight: bold;
    }
    
    .count-entrada::before {
        color: green;
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
    }
    
    .count-salida::before {
        content: '';
        color: red;
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
    }
    
    .item-descripcion {
        float: left;
    }
    
    .item-numero {
        font-weight: bold;
        font-size: 12px;
    }
    
    .item-values {
        float: right;
        font-size: 12px;
    }
    
    .item-stock-zero {
        color: red;
    }
    
    .item-price {
        font-size: 14px;
    }
    
    .wrapper-login {
        padding-top: 100px;
    }
    
    .form-signin {
        max-width: 380px;
        padding: 15px 35px 15px;
        margin: 0 auto;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    
    .form-signin .form-signin-heading,
    .form-signin .checkbox {
        margin-bottom: 30px;
    }
    
    .form-signin .checkbox {
        font-weight: normal;
    }
    
    .form-signin .form-control {
        position: relative;
        font-size: 16px;
        height: auto;
        padding: 10px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    
    .form-signin .form-control:focus {
        z-index: 2;
    }
    
    .form-signin input[type="text"] {
        margin-bottom: -1px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 10px;
    }
    
    .form-signin input[type="password"] {
        margin-bottom: 20px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    
    .container-card {
        padding: 10px;
    }
    
    html[dir=ltr] .nav-links__item--with-submenu>a svg {
        right: 12px;
    }
    
    .row-elemet {
        display: flex;
    }
    
    .custome-active>div.dx-field-item-content>div.dx-show-invalid-badge>div.dx-dropdowneditor-input-wrapper>div.dx-texteditor-container>div.dx-texteditor-input-container>input.dx-texteditor-input {
        background-color: darkgreen;
        color: white;
    }
    
    .custome-noactive>div.dx-field-item-content>div.dx-show-invalid-badge>div.dx-dropdowneditor-input-wrapper>div.dx-texteditor-container>div.dx-texteditor-input-container>input.dx-texteditor-input {
        background-color: rgb(253, 13, 85);
        color: white;
    }
    
    .custome-active>div.dx-field-item-content>div.dx-show-invalid-badge>div.dx-texteditor-container>div.dx-texteditor-input-container>input.dx-texteditor-input {
        background-color: darkgreen;
        color: white;
    }
    
    .custome-noactive>div.dx-field-item-content>div.dx-show-invalid-badge>div.dx-texteditor-container>div.dx-texteditor-input-container>input.dx-texteditor-input {
        background-color: rgb(253, 13, 85);
        color: white;
    }
    
    .no-activo {
        background-color: rgb(255, 210, 223);
    }
    
    .text-admision {
        font-weight: bold;
        color: darkred;
    }
    
    .text-inss {
        font-weight: bold;
        color: darkcyan;
    }
    
    .txt-stockMin {
        font-weight: bold;
        color: rgb(255, 61, 61);
    }
    
    .txt-stock {
        font-weight: bold;
        color: rgb(41, 41, 41);
    }
    
    .text-reference {
        color: rgb(202, 14, 14);
    }
    
    .btn-header {
        margin: 0 5px;
    }
    
    .dx-icon-remove {
        color: red
    }
    
    .mr-10 {
        margin-right: 10px;
    }
    
    .ml-10 {
        margin-left: 10px;
    }
    
    .mt-10 {
        margin-top: 10px;
    }
    
    .mt-25 {
        margin-top: 25px;
    }
    
    .mt-50 {
        margin-top: 50px;
    }
    
    .m0 {
        margin: 0 !important;
    }
    
    .imglog {
        text-align: center;
    }
    
    .citas-lista {
        display: flex;
    }
    
    .cita-hora {
        width: 100px;
        font-weight: bold;
    }
    
    .text-green {
        color: green;
    }
    
    .widget-container {
        display: flex;
    }
    
    .quantity-text {
        background-color: #cbfeff;
    }
    
    .customPrice-text {
        background-color: #cbffd8;
    }
    
    .quantityResponse-text {
        background-color: #54d7da;
    }
    
    .cost-text {
        background-color: #ffdbde;
    }
    
    .price-text {
        background-color: #d2ffe3;
    }
    
    #gridContainer .diff {
        position: relative;
        text-align: right;
    }
    
    #gridContainer .inc .diff:before {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADKSURBVHjaYtTaLs1ABEiG0nPRJa56PEHhsxBhmCUQT4OyrwHxcXyKmQgYJgHE64CYDYrXQcXIMhCbAcgWkGzgNKh38QUB0QamIUUErkhKI9ZAGyCeTERkTYaqxWsgKA2txhdG6GGsvUNGGpeBRMUiGhCFGsqGzUBQQJsxkA5AemaiG5hDIBIIgQSgK0FmMDACs549kN5FZLjhA7+A2A2U9YSAOBeLAk4gnoBDczoOcSFGPIUDPxB/wCHHiKtwYGKgMhg1cBAaCBBgAJTUIL3ToPZfAAAAAElFTkSuQmCC');
    }
    
    #gridContainer .dec .diff:before {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADJSURBVHjaYvzPgBfgkhYA4o8QFahKmBioDEYNHIQGsgBxIBCLkqgvAYi/g1mMjMjir0EJzR6If/6HpChKMMgMe3DKBeIcKhiY8x/MYoDj+RQYNgdkGLqBbEB8kgzDToL1YjEQhKWB+BUJhj0H64Eahs1AELYhMpJ+gtUiGYbLQBBOI8LANLBaIg1kAAc0vkiAqSPBQFAkHcNi2DGoHMkGgrAENOCRI0ECRQ2JBoKwJTQCfkLZDPgMZPxPXN5NhtJzMSsJVBMAAgwAyWSY2svfmrwAAAAASUVORK5CYII=');
    }
    
    #gridContainer .inc .diff:before,
    #gridContainer .dec .diff:before {
        content: '';
        display: block;
        height: 10px;
        width: 10px;
        float: left;
        // position: absolute;
        // right: 0px;
        // top: 6px;
        background-repeat: no-repeat;
        background-size: 10px 10px;
    }
    
    .stock-0 {
        color: red;
    }
    
    .color-icon-red {
        color: red;
    }
    
    .color-icon-green {
        color: green;
    }
    
    .bg-fbfbfb>div>div.dx-popup-content {
        background-color: #fbfbfb;
    }
    
    .pagada {
        color: rgb(18, 136, 18);
    }
    
    .pendiente {
        color: #ff6161;
    }
    
    .col-id-factura {
        font-weight: bold;
        color: darkgreen;
    }
    
    .col-reference-factura {
        font-weight: bold;
        color: rgb(179, 2, 2);
    }
    
    .col-id-receipt {
        font-weight: bold;
        color: darkorange;
    }
    
    .col-id-total {
        font-weight: bold;
    }
    
    .col-id-pagado {
        font-weight: bold;
        color: darkgreen;
    }
    
    .col-id-pendiente {
        font-weight: bold;
        color: #ff6161;
    }
    
    .dash-row {
        display: block;
    }
    
    .card-row {
        display: flex;
        justify-content: space-between;
    }
    
    @media (min-width: 200px) {
        .dash-col-6 {
            width: 100%;
        }
    }
    
    @media (min-width: 900px) {
        .dash-col-6 {
            width: 50%;
            float: left;
        }
    }
    
    .dash-color {
        background-color: #ffffff;
    }
    
    .w-100 {
        width: 100%;
    }
    
    .dash-col {
        display: flex;
        flex-direction: column;
    }
    
    .p-10 {
        padding: 10px;
    }
    
    .mrml10 {
        margin-right: 10px;
        margin-left: 10px;
    }
    
    .m15 {
        margin: 15px;
    }